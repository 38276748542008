import { inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { filter, firstValueFrom, map, timer } from 'rxjs';
import { WINDOW } from '@pinup-teams/common';
import { CookieService } from '@pinup-teams/common';
import { EnvName } from '@pt/models';
import { environment } from '@pt/environment';
import { AuthService } from '@pt/auth';

type WindowExtended = Window & { aft(...args: any): void; aftSID(): string };

const AFT_SESSION_ID_KEY = 'aftSID';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private _window = inject<WindowExtended>(WINDOW);
  private _auth = inject(AuthService);
  private _cookie = inject(CookieService);
  private _document = inject(DOCUMENT);

  init() {
    this._initScript();
    this._setSIDToCookie();
  }

  sendLoginEvent(token: string) {
    if (environment.envName === EnvName.PROD) {
      const { email } = this._auth.decodeJwt(token);
      this._window.aft('tag', email);
      this._window.aft('send');
    }
  }

  private _initScript() {
    const script = this._document.createElement('script');
    script.type = 'text/javascript';

    if (environment.envName === EnvName.PROD || environment.envName === EnvName.STAGE) {
      script.src = `${environment.assetsHost}/assets/scripts/aft-fp-prod.js`;
    } else if (environment.envName === EnvName.DEV) {
      script.src = `${environment.assetsHost}/assets/scripts/aft-fp-dev.js`;
    }
    this._document.head.appendChild(script);
  }

  private _setSIDToCookie() {
    this._aftSID.then(sid => {
      const afterThreeDays = new Date(Date.now() + (3 * 24 * 60 * 60 * 1000)).toUTCString();
      this._cookie.set(AFT_SESSION_ID_KEY, sid, afterThreeDays, environment.cookieDomain);
    });
  }

  private get _aftSID(): Promise<string> {
    return firstValueFrom(
      timer(0, 500).pipe(
        filter(() => !!this._window.aftSID),
        map(() => this._window.aftSID()),
      ),
    );
  }
}
