import { delay, Observable, of } from 'rxjs';

import { GetPositionsSelectOptionsResModel } from '../models';

export function positionsMock(): Observable<GetPositionsSelectOptionsResModel> {
  return of({
    positions: [
      'Event Manager',
      'Project Manager',
      'Team Leader (Angular)',
      'Team Leader (Python)',
      'Chief Legal Officer',
    ],
  }).pipe(delay(500));
}
