import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';

import { environment } from '@pt/environment';
import { BehaviorSubject, map, Observable, switchMap, tap, timer } from 'rxjs';
import { DISABLE_REFRESH_TOKEN_INTERCEPTOR } from '@pt/auth';

@Injectable({
  providedIn: 'root',
})
export class TimeDeltaService extends BehaviorSubject<number> {
  constructor(private _http: HttpClient) {
    super(0);

    timer(0, 30000)
      .pipe(
        switchMap(() => this._getServerTime()),
        tap(serverTime => this.next(this._calculateDelta(serverTime))),
      )
      .subscribe();
  }

  private _calculateDelta(serverTime: number): number {
    return serverTime - Date.now();
  }

  private _getServerTime(): Observable<number> {
    return this._http
      .get(environment.apiHost + 'auth/healthcheck/', {
        observe: 'response',
        context: new HttpContext().set(DISABLE_REFRESH_TOKEN_INTERCEPTOR, true),
      })
      .pipe(map(response => Date.parse(response.headers.get('Date'))));
  }
}
