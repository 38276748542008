import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule, IconModule } from '@pinup-teams/common';
import { environment } from '@pt/environment';

import { AuthService } from '../../auth.service';

@Component({
  standalone: true,
  selector: 'pt-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, ButtonModule, IconModule],
})
export class SigninComponent implements OnInit {
  readonly logoName = `${environment.shortName}-logo`;

  private readonly _auth = inject(AuthService);

  ngOnInit(): void {
    this._auth.addOneTap();
    this._auth.addButton('google-btn-id');
  }
}
