<form
  class="pu-tfa__body"
  [formGroup]="form"
  (ngSubmit)="verify()"
>

  <pu-icon
    iconName="p"
    size="96"
  />

  <h4
    class="pu-tfa__header"
    translate="tfa.header"
  >
  </h4>

  <p
    class="pu-tfa__message"
    translate="tfa.message2"
  >
  </p>

  <pu-simple-input
    class="pu-form-container"
    formControlName="code"
    [errorDictionary]="errorDictionary"
    [hasAutofocus]="true"
    [label]="'tfa.control.code' | translate"
  />

  <div class="pu-tfa__actions">
    <button
      type="submit"
      puButton
      size="l"
      uiType="primary"
      [disabled]="!form.valid || isLoading"
      translate="tfa.btn.verify"
    >
    </button>
  </div>

  <p class="pu-tfa__message">
    {{ 'tfa.help1' | translate }}
    <a
      puButton
      uiType="quaternary"
      (click)="openResetDialog()"
    >
      {{ 'tfa.help2' | translate }}
    </a>
  </p>
</form>
