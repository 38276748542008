import { refreshTokenInterceptor } from './refresh-token.interceptor';
import { authTokenInterceptor } from './auth-token.interceptor';
import { authWithCredentialsInterceptor } from './auth-with-credentials.interceptor';
import { appErrorsInterceptor } from './errors.interceptor';

/**
 * Auth http interceptors
 */
export const AUTH_HTTP_INTERCEPTORS = [
  refreshTokenInterceptor,
  authTokenInterceptor,
  authWithCredentialsInterceptor,
  appErrorsInterceptor,
];
