import { createSelector } from '@ngrx/store';

import { RootState } from '../';
import { UiState } from './';

const selectUiState = (state: RootState) => state.ui;
const selectIsDarkTheme = createSelector(selectUiState, (state: UiState) => state.isDarkTheme);
const selectIsNavbarHiddenByScroll = createSelector(
  selectUiState,
  (state: UiState) => state.isNavbarHiddenByScroll,
);
const selectIsShadowVisibleByScroll = createSelector(
  selectUiState,
  (state: UiState) => state.isShadowVisibleByScroll,
);

export const UiSelectors = {
  selectIsDarkTheme,
  selectIsNavbarHiddenByScroll,
  selectIsShadowVisibleByScroll,
};
