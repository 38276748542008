import { DOCUMENT } from '@angular/common';
import { inject, Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, UrlTree } from '@angular/router';

import { environment } from '@pt/environment';
import { MfeConfig, MfeUrlSegments } from '@pt/mfe';
import { WINDOW } from '@pinup-teams/common';
import { firstValueFrom, map, Observable, of, switchMap, tap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { RootState } from '@pt/store';
import { CredentialResponse, PromptMomentNotification } from 'google-one-tap';
import { AccessData, PersonalInfo } from '@pt/models';

import { AuthActions, AuthSelectors } from './store';
import { REFRESH_TOKEN_KEY } from './auth.constants';
import { accessDataMock, profileMock } from './mocks';

type GoogleWindow = Window & { google: any; onGoogleLibraryLoad: () => void };

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly _zone = inject(NgZone);
  private readonly _http = inject(HttpClient);
  private readonly _router = inject(Router);
  private readonly _store = inject(Store<RootState>);
  private readonly _window = inject<GoogleWindow>(WINDOW);
  private readonly _document = inject(DOCUMENT);
  private readonly _mfeConfig = inject(MfeConfig);
  private readonly _mfeUrlSegments = inject(MfeUrlSegments);
  private readonly _mfeAuthSlug = this._mfeConfig?.apiAuthSlug
    ? `${this._mfeConfig.apiAuthSlug}/`
    : '';

  private readonly _initializedPromise = new Promise(resolve => {
    if (this._window.google?.accounts?.id) {
      this._initialized = true;
      this._initializeGoogle();
      resolve(true);
    } else {
      this._window.onGoogleLibraryLoad = () => {
        if (!this._initialized) {
          this._initialized = true;
          this._initializeGoogle();
          resolve(true);
        }
      };
    }
  });

  private _initialized: boolean;

  addButton(elementId: string): void {
    this._initializedPromise.then(() => {
      const parent = this._document.getElementById(elementId);
      this._window.google.accounts.id.renderButton(parent, { theme: 'filled_black' });
    });
  }

  addOneTap(callback?: (notification: PromptMomentNotification) => void): void {
    this._initializedPromise.then(() => {
      this._window.google.accounts.id.prompt(callback);
    });
  }

  async signedInCheck(): Promise<true | UrlTree> {
    if (environment.useMocks) {
      // eslint-disable-next-line @ngrx/avoid-dispatching-multiple-actions-sequentially
      this._store.dispatch(AuthActions.updateAccessData({ ...accessDataMock }));
      // eslint-disable-next-line @ngrx/avoid-dispatching-multiple-actions-sequentially
      this._store.dispatch(AuthActions.setProfile({ profile: profileMock }));

      return true;
    }

    const profile = await firstValueFrom(this._store.select(AuthSelectors.selectProfile));

    if (profile) {
      return true;
    }

    return firstValueFrom(
      this.check().pipe(
        map((): true => true),
        catchError(() => of(this._getSignInUrlTree())),
      ),
    );
  }

  signedOutCheck(): Observable<boolean> {
    return this.check().pipe(
      map(() => false),
      tap(() => this._store.dispatch(AuthActions.navigateAfter())),
      catchError(() => of(true)),
    );
  }

  check() {
    const refresh = this._window.localStorage.getItem(REFRESH_TOKEN_KEY);

    return this._http.post<Pick<AccessData, 'access' | 'permissions'>>(environment.apiHost
      + `${this._mfeAuthSlug}auth/refresh/`, { refresh }).pipe(
      tap(value => this._store.dispatch(AuthActions.updateAccessData({ ...value }))),
      switchMap(() => this._http.get<PersonalInfo>(environment.apiHost + 'user/personal-info/')),
      tap(profile => this._store.dispatch(AuthActions.setProfile({ profile }))),
    );
  }

  logout(): void {
    this._window.google.accounts.id.disableAutoSelect();
    this._store.dispatch(AuthActions.logout());
  }

  decodeJwt(idToken: string): Record<string, string | string[] | undefined> {
    const base64Url = idToken.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      this._window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );

    return JSON.parse(jsonPayload);
  }

  private _initializeGoogle(): void {
    this._window.google.accounts.id.initialize({
      // Ref: https://developers.google.com/identity/gsi/web/reference/js-reference#IdConfiguration
      client_id: environment.googleClientId,
      callback: (response: CredentialResponse): void => {
        this._zone.run(() => {
          if (response.credential) {
            this._store.dispatch(AuthActions.signIn.action({
              payload: { token: response.credential },
            }));
          } else {
            this._store.dispatch(AuthActions.logout());
          }
        });
      },
    });
  }

  private _getSignInUrlTree(): UrlTree {
    const signinSegment = this._mfeUrlSegments.signin;
    const path = this._window.location.pathname.indexOf(signinSegment) >= 0
      ? ''
      : this._window.location.pathname;

    return this._router.parseUrl(!path || path === '/'
      ? `/${signinSegment}`
      : `/${signinSegment}?url=${encodeURIComponent(path)}`);
  }
}
