<ng-container *puLet="codes$ | async as codes">
  <div class="pu-tfa-codes__body">
    <pu-icon
      iconName="p"
      size="96"
    />

    <h4
      class="pu-tfa-codes__header"
      translate="tfaCodes.header"
    >
    </h4>

    <p
      class="pu-tfa-codes__message"
      translate="tfaCodes.message1"
    >
    </p>

    <ul class="pu-tfa-codes__codes">
      <li
        class="pu-tfa-codes__code"
        *ngFor="let code of codes; trackBy: trackByIndex"
      >
        {{ code }}
      </li>
    </ul>

    <p
      class="pu-tfa-codes__message"
      translate="tfaCodes.message2"
    >
    </p>

    <div class="pu-tfa-codes__actions">
      <button
        type="button"
        puButton
        size="l"
        uiType="primary"
        (click)="ok()"
        translate="tfaCodes.btn.ok"
      >
      </button>

      <button
        type="button"
        puButton
        size="l"
        uiType="secondary"
        (click)="copy(codes)"
        translate="tfaCodes.btn.copy"
      >
      </button>
    </div>
  </div>
</ng-container>
