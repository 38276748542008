import { computed } from '@angular/core';

import { AppError } from '@pinup-teams/common';
import { signalStoreFeature, withComputed, withState } from '@ngrx/signals';

type RequestStatus = 'idle' | 'pending' | 'success' | { error: AppError };

type RequestStatusState = { requestStatus: RequestStatus };

export function withRequestStatus() {
  return signalStoreFeature(
    withState<RequestStatusState>({ requestStatus: 'idle' }),
    withComputed(({ requestStatus }) => ({
      isLoading: computed(() => requestStatus() === 'pending'),
      isSuccess: computed(() => requestStatus() === 'success'),
      error: computed(() => {
        const status = requestStatus();

        return typeof status === 'object' ? status.error : null;
      }),
    })),
  );
}

export function setPending(): RequestStatusState {
  return { requestStatus: 'pending' };
}

export function setSuccess(): RequestStatusState {
  return { requestStatus: 'success' };
}

export function setError(error: AppError): RequestStatusState {
  return { requestStatus: { error } };
}
