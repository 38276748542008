import { Group, User } from '@pt/models';

export enum Surveys {
  TEMPLATES = 'templates',
  MANUALS = 'manuals',
  PULSE = 'pulse',
}

export enum SurveyStatus {
  PLANNED = 'planned',
  FINISHED = 'finished',
  IN_PROGRESS = 'inProgress',
}

export enum SurveyCategories {
  OWN = 'own',
  RECOMMENDED = 'recommended',
  EDITABLE = 'editable',
}

export interface Survey {
  id: number;
  createdAt: string;
}

export interface SurveyTemplate extends Survey {
  name: string;
  reward: number;
  isActive: boolean;
  category: SurveyCategories;
  isRemovable: boolean;
  isEditable: boolean;
}

export interface SurveyHistoryItem extends Survey {
  surveyName: string;
  expireAt: string;
  users: User[];
  groups: Group[];
  status: SurveyStatus;
}

export type SurveyItem = SurveyTemplate | SurveyHistoryItem;
