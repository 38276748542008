import { inject } from '@angular/core';

import { signalStoreFeature, withMethods } from '@ngrx/signals';
import { ToastType } from '@pinup-teams/common';
import { StoreHelperService } from '@pt/services';

export function withRequestHandler() {
  return signalStoreFeature(
    withMethods(() => {
      const storeHelper = inject(StoreHelperService);

      return {
        showSuccessToast: (messageKey: string) => {
          storeHelper.showToast(ToastType.Success, messageKey);
        },
        handleError: storeHelper.handleError,
      };
    }),
  );
}
