import { environment } from '@pt/environment';

/**
 * GOOGLE JWT token local storage key
 */
export const GOOGLE_JWT_TOKEN_KEY = 'google-jwt-token';

/**
 * Auth token local storage key
 */
export const AUTH_TOKEN_KEY = `auth-token-${environment.tokenPostfix}`;

/**
 * Refresh token local storage key.
 * This key is used both for pin-up team and cookie for LMS on subdomain
 */
export const REFRESH_TOKEN_KEY = `refresh-token-${environment.tokenPostfix}`;
