import { HttpHandlerFn, HttpRequest } from '@angular/common/http';

/**
 * Auth with credentials interceptor
 * Adds to request { withCredentials: true }
 */
export function authWithCredentialsInterceptor(request: HttpRequest<any>, next: HttpHandlerFn) {
  const authReq = request.url.includes('auth');
  return next(authReq ? request.clone({ withCredentials: true }) : request);
}
