import { OverlayContainer } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';

@Injectable()
export class MfeOverlayContainer extends OverlayContainer {
  override getContainerElement(): HTMLElement {
    const mfeShellHost = this._document.getElementById('id-pt-mfe-shell-host');
    const mfeOverlayContainer = mfeShellHost.shadowRoot
      .getElementById('id-pt-mfe-overlay-container');
    if (!mfeOverlayContainer) {
      this._createContainer();
    }

    return this._containerElement;
  }

  protected override _createContainer() {
    const mfeShellHost = this._document.getElementById('id-pt-mfe-shell-host');
    const mfeShell = mfeShellHost.shadowRoot.getElementById('id-pt-mfe-shell');
    const container = this._document.createElement('div');
    container.setAttribute('id', 'id-pt-mfe-overlay-container');
    container.classList.add('cdk-overlay-container', 'pt-mfe-overlay-container');
    mfeShell.appendChild(container);
    this._containerElement = container;
  }
}
