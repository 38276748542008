import { NgModule } from '@angular/core';

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { RouterEffects, KeyboardEffects } from '@pinup-teams/common';
import { environment } from '@pt/environment';
import { authReducer, AuthEffects } from '@pt/auth';

import { RootState, uiReducer, UiEffects } from './';

@NgModule({
  imports: [
    StoreModule.forRoot<RootState>({
      router: routerReducer,
      auth: authReducer,
      ui: uiReducer,
    }),
    EffectsModule.forRoot([RouterEffects, AuthEffects, KeyboardEffects, UiEffects]),
    StoreDevtoolsModule.instrument({
      name: 'PINUP',
      maxAge: 25,
      logOnly: environment.production,
    }),
    StoreRouterConnectingModule.forRoot(),
  ],
})
export class RootStoreModule {
}
