<!--
It's MFE styles
-->
<link
  rel="stylesheet"
  [href]="(assetsHost+'/styles.css') | safeUrl"
  (load)="isMfeStylesLoaded.set(true)"
/>

<!--
This file must be located on the Host.
You can change MFE styles with these styles from the Host.
Leave this file empty if you do not need to change MFE styles
-->
<link
  rel="stylesheet"
  href="/pt-mfe-styles.css"
  (load)="isHostStylesLoaded.set(true)"
/>

<div #iconsContainer></div>

<div
  id="id-pt-mfe-shell"
  class="pt-mfe-shell"
  [class.pt-mfe-shell_ready]="isMfeStylesLoaded() && isHostStylesLoaded()"
  #shell
>
  <ng-container *puSpinner="spinner.isSpinning$ | async">
    <router-outlet />
  </ng-container>
</div>
