import { inject, Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { FiltersForm } from '@pinup-teams/common';

@Injectable({ providedIn: 'root' })
export class FilterFormsHelperService {
  private readonly _fb = inject(FormBuilder);

  generateFiltersForm<T extends object>(initialValue: T): FormGroup<FiltersForm<T>> {
    const controls: FiltersForm<T> = Object.keys(initialValue).reduce((acc, key) => {
      acc[key as keyof T] = new FormControl(initialValue[key as keyof T]);

      return acc;
    }, {} as FiltersForm<T>);

    return this._fb.group(controls) as unknown as FormGroup<FiltersForm<T>>;
  }
}
