import { createSelector } from '@ngrx/store';
import { RootState } from '@pt/store';

import { AuthState } from './';

const selectAuthState = (state: RootState) => state.auth;

const selectProfile = createSelector(selectAuthState, (state: AuthState) => state.profile);
const selectUserId = createSelector(selectProfile, state => state.id);
const selectUserLocationId = createSelector(selectProfile, state => state.locationId);
const selectRecoveryCodes = createSelector(
  selectAuthState,
  (state: AuthState) => state.recoveryCodes,
);
const selectIs2faEnabled = createSelector(
  selectAuthState,
  (state: AuthState) => state.is2faEnabled,
);

export const AuthSelectors = {
  selectProfile,
  selectUserId,
  selectUserLocationId,
  selectRecoveryCodes,
  selectIs2faEnabled,
};
