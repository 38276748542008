import { AsyncPipe, NgStyle } from '@angular/common';
import {
  AfterViewInit,
  Component,
  DestroyRef,
  ElementRef,
  HostBinding,
  inject,
  OnInit,
  Renderer2,
  signal,
  viewChild,
  ViewEncapsulation,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router, RouterOutlet } from '@angular/router';

import { tap } from 'rxjs';
import {
  WINDOW,
  SpinnerDirective,
  SpinnerService,
  SafeUrlPipe,
  SvgIconRegistryService,
} from '@pinup-teams/common';
import { SafeHtmlPipe } from '@pt/pipes';
import { environment } from '@pt/environment';

import { MfeConfig } from '../../mfe-config.abstraction';
import { ALL_ICONS } from '../../../icons/generated/icons';

@Component({
  standalone: true,
  selector: 'pt-mfe-app',
  templateUrl: './mfe-app.component.html',
  styleUrls: ['./mfe-app.component.scss'],
  imports: [RouterOutlet, NgStyle, SafeHtmlPipe, AsyncPipe, SpinnerDirective, SafeUrlPipe],
  encapsulation: ViewEncapsulation.ShadowDom, // Blocks host styles influence
})
export class MfeAppComponent implements OnInit, AfterViewInit {
  readonly assetsHost = environment.assetsHost;
  readonly spinner = inject(SpinnerService);
  readonly isMfeStylesLoaded = signal<boolean>(false);
  readonly isHostStylesLoaded = signal<boolean>(false);

  private readonly _router = inject(Router);
  private readonly _renderer = inject(Renderer2);
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _mfeConfig = inject(MfeConfig);
  private readonly _window = inject(WINDOW);
  private readonly _iconsRegistryService = inject(SvgIconRegistryService);

  private readonly _shellRef = viewChild<ElementRef>('shell');
  private readonly _iconsContainer = viewChild<ElementRef>('iconsContainer');

  @HostBinding('attr.id') private _hostId = 'id-pt-mfe-shell-host';

  constructor() {
    // Hack for the first MFE router initiation
    // eslint-disable-next-line @stylistic/max-len
    const currentUrl = `${this._window.location.pathname.substring(1)}${this._window.location.search}`;
    this._updateRouter(currentUrl);
    this._subscribeOnMfeUrlUpdate();
    this._router.setUpLocationChangeListener();
  }

  ngOnInit(): void {
    this._subscribeOnDarkThemeChange();
  }

  ngAfterViewInit(): void {
    this._iconsRegistryService.addIcons(ALL_ICONS, this._iconsContainer().nativeElement);
  }

  private _updateRouter(url: string): void {
    if (url.startsWith(this._mfeConfig.baseHref)) {
      this._router.navigateByUrl(url);
    }
  }

  private _subscribeOnMfeUrlUpdate(): void {
    this._mfeConfig.mfeUrlUpdateSubject
      .pipe(
        tap(url => this._updateRouter(url)),
        takeUntilDestroyed(this._destroyRef),
      )
      .subscribe();
  }

  private _subscribeOnDarkThemeChange(): void {
    this._mfeConfig.darkTheme$
      .pipe(
        tap(isDarkTheme => {
          if (isDarkTheme) {
            this._renderer.addClass(this._shellRef().nativeElement, 'pu-dark-theme');
          } else {
            this._renderer.removeClass(this._shellRef().nativeElement, 'pu-dark-theme');
          }
        }),
        takeUntilDestroyed(this._destroyRef),
      )
      .subscribe();
  }
}
