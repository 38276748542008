import { inject } from '@angular/core';
import { HttpHandlerFn, HttpRequest } from '@angular/common/http';

import { WINDOW } from '@pinup-teams/common';

import { AUTH_TOKEN_KEY } from '../auth.constants';

/**
 * Auth token interceptor
 * Add auth token to request if token is present
 */
export function authTokenInterceptor(request: HttpRequest<any>, next: HttpHandlerFn) {
  const window = inject(WINDOW);
  const authToken = window.localStorage.getItem(AUTH_TOKEN_KEY);
  const signinReq = request.url.indexOf('signin') >= 0 && request.method !== 'DELETE';
  const healthCheckReq = request.url.indexOf('healthcheck') >= 0;
  const assetsI18nReq = request.url.indexOf('assets/i18n') >= 0;
  return next(!signinReq && !assetsI18nReq && !healthCheckReq && authToken
    ? request.clone({ setHeaders: { Authorization: `Bearer ${authToken}` } })
    : request);
}
