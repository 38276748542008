import { inject, Pipe, PipeTransform } from '@angular/core';

import { SelectItem, StrNum } from '@pinup-teams/common';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  standalone: true,
  name: 'daysOptions',
})
export class DaysOptionsPipe implements PipeTransform {
  private _translate = inject(TranslateService);

  transform(month: number) {
    if (!month) {
      return [];
    }

    const date = new Date();
    const year = date.getFullYear();

    return <SelectItem<StrNum>[]>Array(new Date(year, month, 0).getDate() + 1)
      .fill(null)
      .map((_, index) => ({
        id: index ? index : null,
        name: index ? index : this._translate.instant('dictionary.all'),
      }));
  }
}
