import { DestroyRef, inject, Injectable, Signal } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { buffer, debounceTime, filter, map, merge, Subject, tap } from 'rxjs';
import {
  equals, FiltersForm, isString, isEmpty, mapObjToArr, isUndefined,
} from '@pinup-teams/common';

type IndexedParamTypes = Record<string, string>;

interface PageControls {
  limit: FormControl<number>;
  page: FormControl<number>;
}

export interface RouteFiltersSettings<T extends Record<string, any>> {
  filtersState: Signal<T>;
  updateFilters: (filters: Partial<T>) => void;
  // Param type example: 'paramName' | 'paramName:str'
  paramTypes?: string[];
  // Param type example: 'paramName' | 'paramName:str'
  queryParamTypes?: string[];
  debounceFilterKeys?: (keyof T)[];
  useCustomControlsLogic?: boolean;
  getRouteSegments?: (filters: T) => string[];
}

@Injectable()
export class RouteFiltersService<T extends Record<string, any>> {
  readonly #router = inject(Router);
  readonly #activatedRoute = inject(ActivatedRoute);
  readonly #formBuilder = inject(FormBuilder);
  readonly #destroyRef = inject(DestroyRef);

  #filtersState!: Signal<T>;
  #updateFilters!: (filters: Partial<T>) => void;
  #initialFilters!: T;
  #filtersForm!: FormGroup<FiltersForm<T>>;
  #getRouteSegments?: (filters: T) => string[];
  #debounceFilterKeys: (keyof T)[] = ['search'];
  #withoutRouteParams = false;

  #hasParams = false;
  #lastParamFilters: Partial<T> = {};
  #paramTypesIndex: IndexedParamTypes = {};

  #hasQueryParams = false;
  #lastQueryParamFilters: Partial<T> = {};
  #queryParamTypesIndex: IndexedParamTypes = {};

  initialize(settings: RouteFiltersSettings<T>): FormGroup<FiltersForm<T>> {
    const {
      filtersState,
      updateFilters,
      getRouteSegments,
      paramTypes,
      queryParamTypes,
      debounceFilterKeys,
      useCustomControlsLogic,
    } = settings;

    this.#filtersState = filtersState;
    this.#updateFilters = updateFilters;
    this.#getRouteSegments = getRouteSegments;
    this.#initialFilters = filtersState();
    this.#withoutRouteParams = !paramTypes && !queryParamTypes;
    this.#debounceFilterKeys = debounceFilterKeys || this.#debounceFilterKeys;

    if (paramTypes && queryParamTypes && (!paramTypes.length || !queryParamTypes.length)) {
      throw new Error(
        'If there are two param type arrays, they must have their param types explicitly defined',
      );
    }

    if (paramTypes) {
      this.#setParamTypes(paramTypes);
    }

    if (queryParamTypes) {
      this.#setQueryParamTypes(queryParamTypes);
    }

    this.#createFiltersForm();

    if (!this.#withoutRouteParams) {
      // Set the starting route params for the state
      this.#updateFilters({ ...this.#lastParamFilters, ...this.#lastQueryParamFilters });
      this.#subscribeOnRouteParams();
    }

    if (!useCustomControlsLogic) {
      this.#subscribeOnFormControls();
    }

    return this.#filtersForm;
  }

  updateMatPageControls(event: PageEvent): void {
    const controls = this.#filtersForm.controls as PageControls;
    if (event.pageSize !== controls.limit.value) {
      controls.limit.setValue(event.pageSize);
    } else if (event.pageIndex + 1 !== controls.page.value) {
      controls.page.setValue(event.pageIndex + 1);
    }
  }

  #setParamTypes(paramTypes: string[]): void {
    const { params } = this.#activatedRoute.snapshot;

    paramTypes = paramTypes.length
      ? paramTypes
      : this.#convertFiltersToParamTypes(this.#initialFilters);

    this.#hasParams = true;
    this.#setParamTypesIndex(paramTypes, this.#paramTypesIndex);
    this.#lastParamFilters = this.#convertParamsToFilters(params, this.#paramTypesIndex);
  }

  #setQueryParamTypes(paramTypes: string[]): void {
    const { queryParams } = this.#activatedRoute.snapshot;

    paramTypes = paramTypes.length
      ? paramTypes
      : this.#convertFiltersToParamTypes(this.#initialFilters);

    this.#hasQueryParams = true;
    this.#setParamTypesIndex(paramTypes, this.#queryParamTypesIndex);
    this.#lastQueryParamFilters = this.#convertParamsToFilters(
      queryParams, this.#queryParamTypesIndex,
    );
  }

  #createFiltersForm(): void {
    const initialFilters = this.#withoutRouteParams
      ? this.#initialFilters
      : { ...this.#initialFilters, ...this.#lastQueryParamFilters, ...this.#lastParamFilters };

    const controls: FiltersForm<T> = Object.keys(initialFilters).reduce((acc, key) => {
      acc[key as keyof T] = new FormControl(initialFilters[key]);
      return acc;
    }, {} as FiltersForm<T>);

    this.#filtersForm = this.#formBuilder.group(controls) as any as FormGroup<FiltersForm<T>>;
  }

  #subscribeOnRouteParams(): void {
    if (this.#hasParams) {
      this.#activatedRoute.params
        .pipe(takeUntilDestroyed(this.#destroyRef))
        .subscribe(params => {
          const paramFilters = this.#convertParamsToFilters(
            params, this.#paramTypesIndex,
          );
          if (!equals(paramFilters, this.#lastParamFilters)) {
            this.#updateFilters(paramFilters);
            this.#filtersForm.patchValue(paramFilters, { emitEvent: false });
            this.#lastParamFilters = paramFilters;
          }
        });
    }

    if (this.#hasQueryParams) {
      this.#activatedRoute.queryParams
        .pipe(takeUntilDestroyed(this.#destroyRef))
        .subscribe(queryParams => {
          const paramFilters = this.#convertParamsToFilters(
            queryParams, this.#queryParamTypesIndex,
          );
          if (!equals(paramFilters, this.#lastQueryParamFilters)) {
            this.#updateFilters(paramFilters);
            this.#filtersForm.patchValue(paramFilters, { emitEvent: false });
            this.#lastQueryParamFilters = paramFilters;
          }
        });
    }
  }

  #subscribeOnFormControls(): void {
    const trigger = new Subject<void>();
    let timeoutId: any = null;

    merge<Partial<T>[]>(
      ...mapObjToArr(this.#filtersForm.controls, (control, key) => {
        return control.valueChanges.pipe(
          this.#debounceFilterKeys.includes(key) ? debounceTime(700) : tap(),
          filter(value => value !== this.#filtersState()[key]),
          map(value => {
            const hasPageParam = !isUndefined(this.#initialFilters['page']);
            return !hasPageParam || key === 'page' ? { [key]: value } : { [key]: value, page: 1 };
          }),
        );
      }),
    )
      .pipe(
        tap(() => {
          if (!timeoutId) {
            timeoutId = setTimeout(() => {
              timeoutId = null;
              trigger.next();
            }, 30);
          }
        }),
        buffer(trigger),
        map(data => Object.assign({}, ...data)),
        takeUntilDestroyed(this.#destroyRef),
      )
      .subscribe(partialFilters => {
        const prevFilters = this.#filtersState();
        const newFilters = { ...prevFilters, ...(partialFilters as Partial<T>) };

        if (!equals(prevFilters, newFilters)) {
          if (this.#withoutRouteParams) {
            this.#updateFilters(newFilters);
          } else {
            const queryParams = this.#convertFiltersToParams(
              newFilters,
              this.#queryParamTypesIndex,
            );

            this.#router.navigate(
              this.#getRouteSegments ? this.#getRouteSegments(newFilters) : [],
              { queryParams },
            ).then();
          }
        }
      });
  }

  #setParamTypesIndex(paramTypes: string[], paramTypesIndex: IndexedParamTypes): void {
    paramTypes.reduce((acc: IndexedParamTypes, param) => {
      const [key, type] = param.split(':');
      acc[key] = type || '';
      return acc;
    }, paramTypesIndex);
  }

  #convertFiltersToParamTypes(filters: T): string[] {
    return Object.keys(filters).reduce((acc: string[], key) => {
      acc.push(`${key}${isString(filters[key]) ? ':str' : ''}`);
      return acc;
    }, []);
  }

  #convertFiltersToParams(filters: T, paramTypesIndex: IndexedParamTypes): Params {
    return Object.keys(paramTypesIndex).reduce((acc, key) => {
      const type = paramTypesIndex[key];
      const needToSkip = isUndefined(type);

      if (!needToSkip && !isEmpty(filters[key])) {
        switch (type) {
          case 'str':
            acc[key] = filters[key];
            break;

          default:
            acc[key] = JSON.stringify(filters[key]);
        }
      }

      return acc;
    }, {} as Params);
  }

  #convertParamsToFilters(routeParams: Params, paramTypesIndex: IndexedParamTypes): Partial<T> {
    const filters: Record<string, any> = {};

    Object.keys(paramTypesIndex).forEach(key => {
      const type = paramTypesIndex[key];

      if (routeParams[key]) {
        switch (type) {
          case 'str':
            filters[key] = routeParams[key];
            break;

          default:
            try {
              filters[key] = JSON.parse(routeParams[key]);
            } catch (e) {
              filters[key] = this.#initialFilters[key];
            }
        }
      } else {
        filters[key] = this.#initialFilters[key];
      }
    });

    return filters as Partial<T>;
  }
}
