import {
  ChangeDetectionStrategy, Component, DestroyRef, Inject, OnInit, Optional,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { QRCodeModule } from 'angularx-qrcode';
import { TranslateModule } from '@ngx-translate/core';
import {
  ButtonModule, DIALOG_DATA, DialogRef, IconModule, SimpleInputModule, UaParserService,
} from '@pinup-teams/common';
import { FormBuilder } from '@ngneat/reactive-forms';
import { Enable2FAData } from '@pt/models';
import { ActionsSubject, Store } from '@ngrx/store';
import { ofType } from '@ngrx/effects';
import { filter, tap } from 'rxjs';

import { AuthActions } from '../../';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    QRCodeModule,
    ButtonModule,
    IconModule,
    SimpleInputModule,
  ],
  selector: 'pu-tfa-enable',
  templateUrl: './tfa-enable.component.html',
  styleUrls: ['./tfa-enable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TfaEnableComponent implements OnInit {
  token: string;
  manualSetupUri: string;
  isLoading = false;
  isIOS = this._uaParser.isIOS;

  form = this._fb.group({
    code: ['', Validators.required],
  });
  errorDictionary: Record<string, string> = {
    apiValidation: 'errors.invalid_two_factor_code',
  };

  constructor(
    private _fb: FormBuilder,
    private _store: Store,
    private _actionsSubject: ActionsSubject,
    private _uaParser: UaParserService,
    private _dialogRef: DialogRef,
    private _destroyRef: DestroyRef,
    @Optional() @Inject(DIALOG_DATA) public data: Enable2FAData & { token: string },
  ) {
    this.token = data.token;
    this.manualSetupUri = data.manualSetupUri;
  }

  ngOnInit() {
    this._actionsSubject
      .pipe(
        ofType(AuthActions.verifyCode.succeededAction),
        tap(() => {
          this.isLoading = false;
          this._dialogRef.close();
        }),
        takeUntilDestroyed(this._destroyRef),
      )
      .subscribe();

    this._actionsSubject
      .pipe(
        ofType(AuthActions.verifyCode.failedAction),
        tap(() => (this.isLoading = false)),
        filter(({ error }) => error.code === 'invalid_two_factor_code'),
        tap(() => this.form.controls.code.setErrors({ apiValidation: true })),
        takeUntilDestroyed(this._destroyRef),
      )
      .subscribe();
  }

  verify(): void {
    this.isLoading = true;
    this._store.dispatch(AuthActions.verifyCode.action({
      payload: {
        ...this.form.value,
        token: this.token,
      },
    }));
  }
}
