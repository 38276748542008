import { delay, Observable, of } from 'rxjs';
import { fillArr } from '@pinup-teams/common';

import { GetGroupsRes } from '../models';

export function getGroupsMock(count = 12): Observable<GetGroupsRes> {
  return of({
    groups: fillArr(count, i => ({
      id: i,
      name: `Group ${i}`,
    })),
    itemsCount: count,
  }).pipe(delay(500));
}
