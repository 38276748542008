import { patchState, signalStoreFeature, withMethods } from '@ngrx/signals';

export function withFiltersUpdate<T extends object>() {
  return signalStoreFeature(
    withMethods(store => ({
      updateFilters(filters: Partial<T>): void {
        patchState(store, { filters: { ...(store as any).filters(), ...filters } });
      },
    })),
  );
}
