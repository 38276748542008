import { Inject, Injectable } from '@angular/core';

import { NAVIGATOR, ToastType } from '@pinup-teams/common';
import { UiActions } from '@pt/store';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ClipboardService {
  constructor(
    private _store: Store,
    private _translate: TranslateService,
    @Inject(NAVIGATOR) private _navigator: Navigator,
  ) {
  }

  copy(text: string, successMessageKey: string) {
    this._navigator.clipboard.writeText(text).then(() => {
      this._store.dispatch(
        UiActions.showToast({
          toastType: ToastType.Success,
          message: this._translate.instant(successMessageKey),
        }),
      );
    });
  }
}
