import { Operations } from '@pt/models';

export enum SurveyResponders {
  USERS = 'users',
  GROUPS = 'groups',
}

export enum SurveyRecommended {
  NONE = 'none',
  EVERY_ONE = 'everyOne',
  SPECIFICALLY = 'specifically',
}

export interface AvailableOperation {
  id: string;
  order?: number;
  type: Operations;
  name: string;
}
