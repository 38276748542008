import { inject, Injectable } from '@angular/core';
import { HttpStatusCode } from '@angular/common/http';

import { Store } from '@ngrx/store';
import { AppError, ToastType } from '@pinup-teams/common';
import { ErrorsActions } from '@pinup-teams/common';
import { UiActions } from '@pt/store';

@Injectable({ providedIn: 'root' })
export class StoreHelperService {
  readonly handleError = this._handleError.bind(this);
  readonly showToast = this._showToast.bind(this);

  private readonly _store = inject(Store);

  private _handleError(error: AppError): void {
    if (error?.originalError?.status !== HttpStatusCode.NotFound && error?.originalError?.status
      !== HttpStatusCode.Unauthorized) {
      this._store.dispatch(ErrorsActions.unexpectedServerErrorHappened({ error }));
    }
  }

  private _showToast(toastType: ToastType, messageKey: string): void {
    this._store.dispatch(UiActions.showToast({ toastType, message: messageKey }));
  }
}
